import Image from 'next/image';
import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './ListBlock.module.css';

// Components
import RichTextContent from '../RichTextContent/RichTextContent';

function ListBlock({ list, theme, className }) {
  return (
    <div className={cn([styles.wrapper, styles[theme], className])}>
      <ul className={styles.container}>
        {list?.length ? list.map(({ data: item }, index) => (
          <li key={index} className={styles.item}>
            {item?.media?.url ? (
              <div className={styles.image}>
                <Image
                  src={item.media.url}
                  alt={item?.media?.description}
                  fill
                  sizes="48px"
                />
              </div>
            ) : null}
            <RichTextContent content={item?.content} />
          </li>
        )) : null}
      </ul>
    </div>
  );
}

ListBlock.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    images: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    label: PropTypes.string,
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

ListBlock.defaultProps = {
  list: [],
  theme: 'light',
  className: '',
};

export default ListBlock;
